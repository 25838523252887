/* Variables. */
$primary: #6E60AB;
$secondary: darken($primary, 15%);
$tertiary: #5F5755;

$body-color: lighten(#5F5755, 10%);
$body-bg: #ECF5F9;

$link-color: $primary;
$border-color: #efefef;

$call_to_action_bg: #ffd301;
$call_to_action_text: white;

$header-bg-color: white;
$heading-font-color: $tertiary;
$headings-color: $tertiary;

$call_to_action_text: #333;
$bottom-footer-bg-color: white;

$search-home-bg-color: $border-color;
$legenda-available-color: #39b54a;
$legenda-blocked-color: #bbb;

$navbar-dark-color: white;
$navbar-dark-hover-color: lighten($primary, 20%);
$navbar-dark-active-color: white;

$bottom-footer-bg-color: darken($tertiary, 20%);
$footer-background-color: $tertiary;

@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700');
$headings-font-family: 'Noto Sans', Arial, Helvetica, "Nimbus Sans L", sans-serif;
$font-family-base: 'Noto Sans', Arial, Helvetica, "Nimbus Sans L", sans-serif;

/* Flean base imports */
@import "../../flean_base_2019/sass/imports";

@include media-breakpoint-up(sm){
  .front a.column {
    .h-100 {
      align-items: center;
      text-align: center;
      .image {
        margin: 20px;
        border-radius: 50%;
        overflow: hidden;
        padding-bottom: 60%;
        height: 0;
        width: 60%;
        position: relative;

        img {
          position: absolute;
          height: 100%;
          width: 200%;
          margin-left: -100%;
          max-width: none;
        }
      }
    }
  }
}
